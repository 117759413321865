import React from 'react';
import styled, { StyleSheetManager } from 'styled-components';

import { Box, Grid } from '@core';
import { ImageCore, LinkWrapper, Text } from '@components';

const CardWrapper = styled(Box)`
  direction: ltr; // this card is used only in english
`;

const DealsReviewCard = ({ text, image, name, rating, link, isWithLink }) => (
  // overrriding rtl plugin as this component is only in english
  <StyleSheetManager stylisPlugins={[]}>
    <CardWrapper>
      <LinkWrapper link={isWithLink ? link : null} target="_blank">
        <Grid.Container>
          <Grid.Row>
            <Text.Body1>{text}</Text.Body1>
          </Grid.Row>
          <Grid.Row mt={18} alignItems="center">
            <Box width={80}>
              <ImageCore src={image} alt={name} />
            </Box>
            <Box display="flex" flexDirection="column" ml={16}>
              <Text.Body3Strong>{name}</Text.Body3Strong>
              <Text.Body3Strong mt={8} fontFamily="title" fontSize={20} lineHeight="25px">
                {rating}
              </Text.Body3Strong>
            </Box>
          </Grid.Row>
        </Grid.Container>
      </LinkWrapper>
    </CardWrapper>
  </StyleSheetManager>
);

export default DealsReviewCard;
